import React from "react"
import Layout from "../components/layout"
import InnerPageTwitterLayout from "../components/inner-page-twitter-layout"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileDownload } from '@fortawesome/free-solid-svg-icons'

export default function PolicyBullying() {
  return ( 
    <Layout>
        <section className="container">
            <h1>Anti-Bullying Policy <a href="downloads/LCJFC Antibullying Policy.pdf" target="_blank" title="download policy"><FontAwesomeIcon className="lcj-color" icon={faFileDownload}></FontAwesomeIcon></a></h1>
            <InnerPageTwitterLayout>
<div className="small">
<h5>Statement of Intent</h5>
<p>We are committed to providing a caring, friendly and safe environment for all our members so they can participate in football in a relaxed and secure atmosphere.  Bullying of any kind is unacceptable at our club.  If bullying does occur, all club members or parents should be able to tell and know that incidents will be dealt with promptly and effectively.  We are a TELLING club.  This means that anyone who knows that bullying is happening is expected to tell the club welfare officer or any committee member. </p>

<h5>Objectives of this policy</h5>
<p>
    <ul>
    <li>All club members, coaches, officials and parents should have an understanding of what bullying is. </li>
    <li>All club members, officials and coaching staff should know what the club policy is on bullying, and follow it when bullying is reported. </li>
    <li>All players and parents should know what the club policy is on bullying, and what they should do if bullying arises </li>
    <li>As a club we take bullying seriously.  Players and parents should be assured that they should be supported when bullying is reported </li>
    <li>Bullying will not be tolerated. </li>
    </ul>
</p>

<h5>What is Bullying?</h5>
<p>
Bullying is the use of aggression with the intention of hurting another person. Bullying results in pain and distress to the victim. 
Bullying can be: 
<ul>
<li>Emotional being unfriendly, excluding (emotionally and physically) sending hurtful text messages, tormenting, (e.g. hiding football boots/shin guards, threatening gestures) </li>
<li>Physical pushing, kicking, hitting, punching or any use of violence </li>
<li>Sexual unwanted physical contact or sexually abusive comments </li>
<li>Discrimination racial taunts, graffiti, gestures, homophobic comments, jokes about disabled people, sexist comments, </li>
<li>Verbal name-calling, sarcasm, spreading rumours, teasing </li>
</ul>
</p> 

<h5>Cyberbullying</h5>
<p>
This is when a person uses technology i.e. mobile phones or the internet (social networking sites, chat rooms, instant messenger, tweets), to deliberately upset someone. Bullies often feel anonymous and ‘distanced’ from the incident when it takes place online and ‘bystanders’ can easily become bullies themselves by forwarding the information on. There is a growing trend for bullying to occur online or via texts – bullies no longer rely on being physically near to the young person. 
This club commits to ensure our website websites and/or social networking pages are being used appropriately and any online bullying will be dealt with swiftly and appropriately in line with procedures detailed in this policy. 
</p>

<h5>Why is it Important to Respond to Bullying? </h5>
<p>Bullying hurts. No one deserves to be a victim of bullying. Everybody has the right to be treated with respect. Individuals who are bullying need to learn different ways of behaving. 
This club has a responsibility to respond promptly and effectively to issues of bullying. 
</p>
<h5>Signs and Indicators </h5>
<p>A child may indicate by signs or behaviour that he or she is being bullied. Adults should be aware of these possible signs and that they should investigate if a child: 
<ul>
<li>says he or she is being bullied </li>
<li>is unwilling to go to club sessions </li>
<li>becomes withdrawn anxious, or lacking in confidence </li>
<li>feels ill before training sessions </li>
<li>comes home with clothes torn or training equipment damaged </li>
<li>has possessions go “missing” </li>
<li>asks for money or starts stealing money (to pay the bully) </li>
<li>has unexplained cuts or bruises </li>
<li>is frightened to say what’s wrong </li>
<li>gives improbable excuses for any of the above. </li>
</ul>

In more extreme cases: 
<ul>
<li>starts stammering </li>
<li>cries themselves to sleep at night or has nightmares </li>
<li>becomes aggressive, disruptive or unreasonable </li>
<li>is bullying other children or siblings </li>
<li>stops eating </li>
<li>attempts or threatens suicide or runs away. </li>
</ul>

These signs and behaviours may indicate other problems, but bullying should be considered a possibility and should be investigated. 
</p>

<h5>Bullying as a result of any form of discrimination </h5>
<p>
Bullying because of discrimination occurs when bullying is motivated by a prejudice against certain people or groups of people. This may be because of their gender, age, race, nationality, ethnic origin, religion or belief, sexual orientation, gender reassignment, disability or ability. 
Generally, these forms of bullying look like other sorts of bullying, but in particular it can include: 
Verbal abuse – derogatory remarks about girls or women, suggesting girls and women are inferior to boys and men, or that black, Asian and ethnic minority people are not as capable as white people; spreading rumours that someone is gay, suggesting that something or someone is inferior and so they are “gay” – for example, “you’re such a gay boy!” or “those trainers are so gay!” Ridiculing someone because of a disability or mental health related issue, or because they have a physical, mental or emotional developmental delay. Referring to someone by the colour of their skin, rather than their name; using nicknames that have racial connotations; isolating someone because they come from another country or social background etc. 
<ul>
<li>Physical abuse – including hitting, punching, kicking, sexual assault, and threatening behaviour. </li>
<li>Cyberbullying – using online spaces to spread rumours about someone or exclude them. It can also include text messaging, including video and picture messaging. </li>
</ul>

Discrimination is often driven by a lack of understanding which only serves to strengthen stereotypes and can potentially lead to actions that may cause women, ethnic minorities, disabled people, lesbian, gay, bisexual or transgender people, or people who follow specific religions or beliefs, to feel excluded, isolated or undervalued. Ensure that club members know that discriminatory language and behaviour will not be tolerated in this club. • If an incident occurs, members should be informed that discriminatory language is offensive, and will not be tolerated. If a member continues to make discriminatory remarks, explain in detail the effects that discrimination and bullying has on people. If it is a young person making the remarks their parents should be informed just as in any breach of the clubs Code of Conduct and this Anti-Bullying policy. 
<ul>
<li>If a member makes persistent remarks, they should be removed from the training setting in line with managing challenging behaviour and the Club Welfare Officer or club officials should talk to them in more detail about why their comments are unacceptable. </li>
<li>If the problem persists, the member should be made to understand the sanctions that will apply if they continue to use discriminatory language or behaviour. </li>
<li>Consider inviting the parents/carers to the club to discuss the attitudes of the youth member in line with the procedures detailed in this policy. </li>
</ul>
</p> 

<h5>Procedures</h5>
<p>
1.	Report bullying incidents to the Club Welfare Officer or a member of the clubs committee <br/>
2.	In cases of serious bullying, the incidents will be referred to the County FA Welfare Officer for advice and possibly to The FA Case Management Team <br/>
3.	Parents should be informed and will be asked to come in to a meeting to discuss the problem <br/>
4.	If necessary and appropriate, the police will be consulted 5. The bullying behaviour or threats of bullying must be investigated and the bullying stopped quickly <br/>
6.	An attempt will be made to help the bully (bullies) change their behaviour <br/>
7.	If mediation fails and the bullying is seen to continue the club will initiate disciplinary action under the club constitution. 
</p>
<h5>Recommended club action</h5>
<p>
If the club decides it is appropriate for them to deal with the situation they should follow the procedure outlined below: <br/>
1.	Reconciliation by getting the parties together. It may be that a genuine apology solves the problem. <br/>
2.	If this fails/not appropriate a small panel (made up from Chairman, Club Welfare Officer, Secretary, committee members) should meet with the parent and child alleging bullying to get details of the allegation. Minutes should be taken for clarity, which should be agreed by all as a true account. <br/>
3.	The same 3 persons should meet with the alleged bully and parent/s and put the incident raised to them to answer and give their view of the allegation. Minutes should again be taken and agreed. <br/>
4.	If bullying has in their view taken place the individual should be warned and put on notice of further action i.e. temporary or permanent suspension if the bullying continues. Consideration should be given as to whether a reconciliation meeting between parties is appropriate at this time. <br/>
5.	In some cases the parent of the bully or bullied player can be asked to attend training sessions, if they are able to do so, and if appropriate. The club committee should monitor the situation for a given period to ensure the bullying is not being repeated. <br/>
6.	All coaches involved with both individuals should be made aware of the concerns and outcome of the process i.e. the warning. 
</p>
<h5>In the case of adults reported to be bullying anyone within the club under 18</h5>
<p>
1.	The County Welfare Officer should always be informed and will advise on action to be taken where appropriate, this may include action by The FA Safeguarding Team. <br/>
2.	It is anticipated that in most cases where the allegation is made regarding a team manager, official or coach, The FA’s Safeguarding Children Education Programme may be recommended. <br/>
3.	More serious cases may be referred to the Police and/or Children’s Social Care.
</p> 
<h5>Prevention:</h5>
<p>
<ul>
<li>The club will have a written constitution, which includes what is acceptable and proper behaviour for all members of which the anti bullying policy is one part. </li>
<li>All club members and parents will sign to accept the constitution upon joining the club. </li>
<li>The Club Welfare Officer will raise awareness about bullying and why it matters, and if issues of bullying arise in the club, will consider meeting with members to discuss the issue openly and constructively. </li>
</ul>
 
This policy is based on guidance provided to schools by KIDSCAPE. KIDSCAPE is a voluntary organisation committed to help prevent child bullying. KIDSCAPE can be contacted on 0207 730 3300 or you can access their website via www.kidscape.org.uk <br/>
You may also wish to access any of the following websites designed to give advice and guidance to parents and children who are faced with dealing with bullying: Guidance for parents/carers <br/>
www.anti-bullyingalliance.org.uk/ www.stoptextbully.com www.beatbullying.org.uk www.stonewall.org.uk www.bullying.co.uk Guidance for young people 
www.youngstonewall.org.uk/ www.cybermentors.org.uk www.childline.org.uk <br/>
We would like to thank the ASA who have shared their Anti Bullying Policy for Clubs and to Stonewall’s guidance from which this recommended FA Club Anti Bullying Policy has been developed. 
</p>
</div> 

            </InnerPageTwitterLayout>
        </section>
    </Layout>
  );
}
